import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import { Link, graphql } from "gatsby";
import BasicTable from "components/basicTable";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "aboutme"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    aboutme: file(relativePath: { eq: "about/aboutme.png"}) { ...normalImg },
    mail: file(relativePath: { eq: "about/mail.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`運営者情報`}</h1>
    <h2 {...{
      "id": "あいさつ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%82%E3%81%84%E3%81%95%E3%81%A4",
        "aria-label": "あいさつ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`あいさつ`}</h2>
    <p>{`はじめまして、パソコン選び方ガイドの管理人です。`}</p>
    <p>{`情報系の研究室にいたころに、取り敢えずホームページでも暇だから作ってみるかということで作成したサイトなのですが、いつの間にかアクセス数が40万PVを超え、それなりの規模となってしまったので仕方なくメンテしています。`}</p>
    <h2 {...{
      "id": "プロフィール詳細",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%97%E3%83%AD%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E8%A9%B3%E7%B4%B0",
        "aria-label": "プロフィール詳細 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`プロフィール詳細`}</h2>
    <BasicTable mdxType="BasicTable">
  <tbody>
    <tr><td style={{
            width: '40px'
          }}>出身</td><td>滋賀県大津市</td></tr>
    <tr><td>居住</td><td>東京都港区</td></tr>
    <tr><td>学歴</td><td>大阪大学基礎工学部卒<br />大阪大学基礎工学研究科システム創成専攻卒</td></tr>
    <tr><td>仕事</td><td>Braviaの組み込みソフトウェア開発、PlayStationStoreの開発、PS4/PS5のシステムソフトウェア開発など</td></tr>
    <tr><td>資格</td><td>ソフトウェア開発技術者(今の応用情報技術者)など</td></tr>
  </tbody>
    </BasicTable>
    <p>{`大学ではAI技術を用いたWebデータマイニングを専攻しており、就職した後、会社ではソフトウェア開発を中心に行っています。といっても最近はプログラミングをすることも少なくなって来たので、本サイトやiOSのアプリケーションを通して趣味でプログラミングをしています。`}</p>
    <p>{`また、以前新宿のヨドバシ本店に一定期間派遣されて働いていたこともありましたので、ユーザー目線で記事を書くことに多少役立ってくれているかなと思っております。`}</p>
    <p>{`会社を代表しての発言は当サイトにはございません。`}</p>
    <Image {...props} name="aboutme" alt="自己紹介" mdxType="Image" />
    <h2 {...{
      "id": "当サイトの目的",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%BD%93%E3%82%B5%E3%82%A4%E3%83%88%E3%81%AE%E7%9B%AE%E7%9A%84",
        "aria-label": "当サイトの目的 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`当サイトの目的`}</h2>
    <p>{`初心者、中上級者問わず自分の目的に合ったスペックのパソコンを探しだせることを本サイトの目的としています。`}</p>
    <p>{`Webサイトの紙と違う特徴はユーザがインタラクションを行えることなので、その特徴を生かしてアンケートに答えることによるパソコンの推薦や、用途別のランキングなどを作成して、より目的にあったパソコンを探し出せるように工夫しています。`}</p>
    <p>{`当サイトを通じて、スペック不足で失敗しない、あるいは過剰にお金をかけすぎないパソコンの選び方ができるようになってもらえれば幸いです。`}</p>
    <h2 {...{
      "id": "お問い合わせ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B",
        "aria-label": "お問い合わせ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`お問い合わせ`}</h2>
    <div style={{
      width: '300px'
    }}>
      <Image {...props} name="mail" alt="address" mdxType="Image" />
    </div>
    <p>{`上記アドレス当てにメールを下さい。遅くとも１週間以内には返信ができるかと思います。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      